import gql from 'graphql-tag';

export type Screenshot = {
  uri: string;
  width: number;
  height: number;
};

export interface GetAppResponse {
  app: {
    previewImage: {
      uri: string;
    };
    icon: {
      uri: string;
    };
    screenshots: Screenshot[];
    developer: {
      name: string;
    };
  };
}

export const getApp = gql`
  query app($id: ID!, $clientIpAddress: String) {
    app(id: $id, clientIpAddress: $clientIpAddress) {
      previewImage(scale: 1.0) {
        uri
      }
      icon(width: 120, height: 120) {
        uri
      }
      screenshots {
        uri
        width
        height
      }
      developer {
        name
      }
    }
  }
`;
