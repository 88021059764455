import React from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';

import { FitLink as Link } from '../basic-components';
import { Wrapper, DesktopList, SeeAllCard } from './common';
import { SeeAllArrow } from './SeeAllCard';
import { useI18n } from '../../hooks';

import { above } from '../../media-query-utils';

interface Props {
  children: React.ReactNode;
  outlink: string;
  type: string;
  seeAllProps?: any;
}

const MobileList = styled(SwipeableViews).attrs({
  style: {
    overflowX: 'visible',
    width: '42vw'
  },
  slideStyle: {
    overflow: 'visible',
    padding: '0 16px 0 0'
  },
  resistance: true,
  disableLazyLoading: true
})`
  ${above('mobile')} {
    display: none;
  }
`;

const ClockRow: React.FC<Props> = ({ children, outlink, seeAllProps }) => {
  const { t } = useI18n();

  return (
    <Wrapper>
      <MobileList>{children}</MobileList>
      <DesktopList>
        {children}
        <Link id={`${outlink}`} to={outlink} {...seeAllProps}>
          <SeeAllCard>
            {t`See All`}
            <SeeAllArrow />
          </SeeAllCard>
        </Link>
      </DesktopList>
    </Wrapper>
  );
};

export default ClockRow;
