import React from 'react';
import { Redirect } from 'react-router';
import { ChildDataProps, graphql } from 'react-apollo';
import Helmet from 'react-helmet';
import CardList from '../components/CardList';

import {
  getPageLayout,
  GetPageLayoutResponse,
  GetPageLayoutVariables,
  LayoutItem,
  AppList
} from '../graphql/getPageLayout';
import { Section, Text, FitLink } from '../components/basic-components';
import { allCategoriesIds } from '../sharedConfig';
import DiscoveryCardRow from '../components/DiscoveryCardRow';
import AppTileCard from '../components/AppTileCard';
import styled from 'styled-components';
import DiscoveryBannerCarousel from '../components/DiscoveryBannerCarousel';
import { below } from '../media-query-utils';
import ClockTileCard from '../components/ClockTileCard';
import AllCallout from '../components/AllCallout';
import PremiumNotice from '../components/PremiumNotice';
import { App } from '../types';
import { LoadingPage } from '../components/LoadingPage';
import { useI18n } from '../hooks';
import { getPreviewImage } from '../utils';

export type DiscoveryProps = {
  type: string;
  layoutId?: string;
  clientIpAddress?: string;
};

type ChildProps = ChildDataProps<
  DiscoveryProps,
  GetPageLayoutResponse,
  GetPageLayoutVariables
>;

type HeaderProps = {
  isApp: boolean;
};

type CardBodyProps = {
  app: App;
  item: AppList;
  type: string;
};

type AppListBodyProps = {
  item: AppList;
  type: string;
};

type FlatListBodyProps = {
  item: AppList;
  type: string;
};

const Wrapper = styled.div`
  padding-bottom: 50px;

  ${below('mobile')} {
    padding-bottom: 40px;

    overflow-x: hidden;
  }
`;

const Title = styled(Text)`
  font-size: 30px;
  font-weight: 600;

  ${below('mobile')} {
    font-size: 20px;
  }
`;

const AppListTitle = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

const SeeAllLink = styled(FitLink)`
  display: none;

  ${below('mobile')} {
    display: block;
    white-space: nowrap;
  }
`;

const PremiumNoticeContainer = styled(Section)`
  padding-bottom: 30px;

  ${below('mobile')} {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const Header: React.FC<HeaderProps> = ({ isApp }) => {
  const { t } = useI18n();

  const title = isApp
    ? t`Discover Smartwatch Apps`
    : t`Discover Smartwatch Clock Faces`;

  const description = isApp
    ? t`Whether you want to start your favorite music station or see your latest cycling route, you can make your watch experience more personal by downloading popular apps from the Fitbit App Gallery right to your watch.`
    : t`With designs that range from super sleek to fitness-focused, the Fitbit App Gallery has clock faces for every occasion.`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
    </Helmet>
  );
};

const CardBody: React.FC<CardBodyProps> = ({ app, item, type }) => {
  const TileCard = type === 'APP' ? AppTileCard : ClockTileCard;

  return (
    <TileCard
      key={app.id}
      img={getPreviewImage(app)}
      name={app.name}
      author={app.developer.name}
      id={app.id}
      isPremium={app.isPremium}
      data-discoveryitemname={item.title}
      data-discoveryitemtype={item.link.type}
      data-discoveryitemid={item.link.resourceId}
      data-appname={app.name}
      data-appdeveloper={app.developer.name}
      data-appid={app.id}
      data-apptype={type}
      data-applink
    />
  );
};

const AppListBody: React.FC<AppListBodyProps> = ({ item, type }) => {
  const { t } = useI18n();

  if (item.apps && item.apps.length === 0) {
    return null;
  }

  const link = `/${item.link.type.toLowerCase()}/${item.link.resourceId}`;

  // Skip special "all" collection ids that will be rendered differently
  if (allCategoriesIds.includes(item.id)) {
    return null;
  }

  const seeAllProps = {
    'data-discoveryitemname': item.title,
    'data-discoveryitemtype': item.link.type,
    'data-discoveryitemid': item.link.resourceId,
    'data-discoveryitemseeall': true
  };

  return (
    <Section key={item.id}>
      <AppListTitle>
        <Title>
          {item.title && item.title.includes('Primary') ? null : item.title}
        </Title>
        <SeeAllLink to={link} {...seeAllProps}>
          {// translator: This is short for 'See All Clocks", "See All Apps" or similar phrases
          t`See All`}
        </SeeAllLink>
      </AppListTitle>
      <DiscoveryCardRow type={type} outlink={link} seeAllProps={seeAllProps}>
        {item.apps.map((app: App) => (
          <CardBody key={app.id} app={app} item={item} type={type} />
        ))}
      </DiscoveryCardRow>
    </Section>
  );
};

const FlatListBody: React.FC<FlatListBodyProps> = ({ item, type }) => {
  if (item.apps && item.apps.length === 0) {
    return null;
  }
  if (allCategoriesIds.includes(item.id)) {
    return null;
  }

  return (
    <Section key={item.id}>
      <CardList>
        {item.apps.map((app: App) =>
          app.type === 'APP' ? (
            <AppTileCard
              key={app.id}
              img={getPreviewImage(app)}
              name={app.name}
              author={app.developer.name}
              id={app.id}
              isPremium={app.isPremium}
            />
          ) : (
            <ClockTileCard
              key={app.id}
              img={getPreviewImage(app)}
              name={app.name}
              author={app.developer.name}
              id={app.id}
              isPremium={app.isPremium}
            />
          )
        )}
      </CardList>
    </Section>
  );
};

class Discovery extends React.Component<ChildProps> {
  private displayPremiumNotice() {
    return this.props.type === 'CLOCK';
  }

  private renderItem = (item: LayoutItem) => {
    if (item.type === 'BANNER_LIST' && item.banners) {
      if (item.banners.length) {
        return (
          <React.Fragment key="banner">
            <DiscoveryBannerCarousel banners={item.banners} key={item.id} />
            {this.displayPremiumNotice() && (
              <PremiumNoticeContainer>
                <PremiumNotice />
              </PremiumNoticeContainer>
            )}
          </React.Fragment>
        );
      }
    } else if (item.type === 'APP_LIST') {
      return (
        <AppListBody
          item={item as AppList}
          type={this.props.type}
          key={item.id}
        />
      );
    } else if (item.type === 'FLAT_APP_LIST') {
      return (
        <FlatListBody
          item={item as AppList}
          type={this.props.type}
          key={item.id}
        />
      );
    }

    return;
  };

  public render() {
    const { type, data, clientIpAddress } = this.props;
    const { pageLayout, loading, error } = data;

    if (loading) {
      return <LoadingPage />;
    }

    const isApp = type === 'APP';

    if (error) {
      return <Redirect to={`/error/${isApp ? 'apps' : 'clocks'}`} />;
    }

    return (
      <React.Fragment>
        <Wrapper>
          <Header isApp={isApp} />
          {pageLayout && pageLayout.map(this.renderItem)}
        </Wrapper>
        <AllCallout isApp={isApp} clientIpAddress={clientIpAddress} />
      </React.Fragment>
    );
  }
}

export default graphql<
  DiscoveryProps,
  GetPageLayoutResponse,
  GetPageLayoutVariables,
  ChildProps
>(getPageLayout, {
  options: (props) => ({
    variables: {
      type: props.type,
      clientIpAddress: props.clientIpAddress,
      id: props.layoutId
    }
  })
})(Discovery);
