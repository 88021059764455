import styled from 'styled-components';

import TileCard from '../TileCard';
import arrow from '../../images/icons/small-arrow.svg';

const SeeAllCard = styled(TileCard)`
  display: flex;
  justify-content: center;
  align-items: center;

  &&& {
    margin: 0;
  }
`;

export const SeeAllArrow = styled.span`
  position: relative;
  top: 2px;
  left: 4px;

  &:after {
    content: url('${arrow}');
  }
`;

export default SeeAllCard;
