import * as React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';

import {
  Text,
  BannerTitle as BaseTitle,
  BannerSubTitle,
  NoStyleLink,
  BaseLink,
  baseFitLinkStyle,
  baseButtonLinkStyle
} from './basic-components';
import { colors } from '../style-constants';
import { above, below } from '../media-query-utils';
import { DeviceForScreenshot } from './Devices';
import { Screenshot } from '../graphql/getApp';
import { useI18n } from '../hooks';
import { BannerOptions } from '../types';

export interface Props {
  active: boolean;
  bannerImage: string;
  bannerOptions?: BannerOptions;
  screenshot?: Screenshot;
  title: string;
  subtitle?: string;
  description: string;
  link: string;
  linkText: string;
  linkType: string;
  badgeText?: string;
  dots?: React.ReactNode;
}

interface ContainerProps {
  active: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  width: 100%;
  height: 98%;

  ${above('mobile')} {
    align-items: center;

    &&& {
      transition: opacity 0.5s ease-in-out;

      opacity: ${ifProp('active', 1, 0)};
    }
  }

  ${below('mobile')} {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    height: 100%;
    margin-left: 5px;
    margin-right: 5px;

    border-radius: 15px;
    box-shadow: 0 0 10px 0 rgba(64, 95, 107, 0.1);
    background-color: white;
    overflow: hidden;
  }
`;

const BannerInfoSection = styled.div<{ deviceShowing: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 44%;
  padding-left: ${ifProp('deviceShowing', '12', '8')}%;
  padding-bottom: 5%;

  justify-content: center;
  align-items: flex-start;

  ${above('smallDesktop')} {
    padding-bottom: 0;
  }

  ${below('mobile')} {
    padding: 20px;
    max-width: 100%;
  }
`;

const BannerTitle = styled(BaseTitle)`
  ${below('smallDesktop')} {
    margin-bottom: 10px;
    font-size: 25px;
  }
`;

const Description = styled(Text)`
  margin-bottom: 20px;

  font-size: 18px;
  line-height: 1.38;

  @media (min-width: 600px) and (max-width: 700px) {
    display: none;
  }

  ${below('mobile')} {
    margin-top: 10px;
    margin-bottom: 0;

    font-size: 16px;
  }
`;

const FullBannerLink = styled(NoStyleLink)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${above('mobile')} {
    display: none;
  }
`;

const SeeMoreLink = styled(BaseLink)`
  font-size: 14px;

  z-index: 1;

  ${above('tablet', 1)} {
    ${baseButtonLinkStyle}
  }

  ${below('tablet')} {
    ${baseFitLinkStyle}
  }

  ${below('mobile')} {
    display: none;
  }
`;

// This allows us to place the Screenshot from it's midpoint instead of it's
// top left corner, keepingit centered on the divide between banner and info
const CenteringDiv = styled.div`
  position: absolute;

  ${above('mobile')} {
    display: flex;
    align-items: center;
    left: 50%;
    height: 100%;
    padding: 3% 0;
    transform: translateX(-50%);
  }

  ${below('mobile')} {
    left: 20px;
    top: calc(50% + 115px);

    .device {
      display: none;
    }
  }
`;

const BannerImageContainer = styled.div`
  position: relative;
  width: 50%;
  min-width: 50%;
  padding-top: 33%;

  ${below('mobile')} {
    width: 100%;
    height: auto;
    padding-top: 66.67%;
  }
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  object-fit: contain;

  background-color: ${colors.silver};
`;

const SubTitle = styled(BannerSubTitle)`
  ${below('largeTablet', -80)} {
    display: none;
  }
`;

const MobileIcon = styled.img`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 70px;

  background-color: black;
  border: 5px solid white;
  border-radius: 8px;

  ${above('mobile')} {
    display: none;
  }
`;

const LearnMoreLink: React.FC<any> = (props) => {
  const { t } = useI18n();

  return <SeeMoreLink {...props}>{t`Learn More`}</SeeMoreLink>;
};

export default class DiscoveryBanner extends React.Component<Props> {
  private createLink = () => {
    const { link, linkType } = this.props;

    if (linkType === 'url') {
      return link;
    }

    return `/${linkType}/${link}`;
  };

  public render() {
    const {
      bannerImage,
      title,
      subtitle,
      description,
      linkType,
      link,
      screenshot,
      dots,
      active
    } = this.props;

    const bannerData = {
      'data-bannertitle': title,
      'data-bannertype': linkType,
      'data-bannertypeid': link,
      'data-banner': true
    };

    return (
      <Container active={active}>
        <BannerImageContainer>
          <BannerImage src={bannerImage} />
          {screenshot && <MobileIcon src={screenshot.uri} />}
        </BannerImageContainer>
        <BannerInfoSection deviceShowing={!!screenshot}>
          <BannerTitle>{title}</BannerTitle>
          <SubTitle>{subtitle}</SubTitle>
          <Description>{description}</Description>
          <LearnMoreLink
            to={this.createLink()}
            external={linkType === 'url'}
            showArrow
            {...bannerData}
          />
          {dots}
        </BannerInfoSection>
        {screenshot && (
          <CenteringDiv>
            <DeviceForScreenshot screenshot={screenshot} className="device" />
          </CenteringDiv>
        )}
        <FullBannerLink
          to={this.createLink()}
          external={linkType === 'url'}
          {...bannerData}
        />
      </Container>
    );
  }
}
