import styled from 'styled-components';
import { Text } from './basic-components';
import { colors } from '../style-constants';

const TileCard = styled.div`
  width: 180px;
  height: 240px;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(64, 95, 107, 0.15);
  background-color: white;
  overflow: hidden;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: normal;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SubTitle = styled(Text)`
  font-size: 14px;
  line-height: 1.5;
  color: ${colors.submarine};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default TileCard;
