import * as React from 'react';
import styled from 'styled-components';
import TileCard, { SubTitle, Title } from './TileCard';
import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import { NoStyleLink } from './basic-components';

interface Props {
  img: string;
  name: string;
  author: string;
  id: string;
  isPremium?: boolean;
}

const LinkWrapper = styled(NoStyleLink)`
  ${below('mobile')} {
    width: 100%;
  }
`;

const AppIcon = styled.img`
  width: 120px;
  margin: 30px;
`;

const InfoWrapper = styled.div`
  padding: 10px;
  border-top: solid 1px ${colors.lightWhisper};

  overflow: hidden;

  ${below('mobile')} {
    border: none;
    padding-top: 20px;
  }
`;

const ResponsiveTileCard = styled(TileCard)`
  ${below('mobile')} {
    display: flex;
    width: 100%;
    height: 80px;

    & img {
      width: 60px;
      margin: 10px;
    }
  }
`;

class AppTileCard extends React.Component<Props> {
  public render() {
    const { img, name, author, id, ...props } = this.props;
    return (
      <LinkWrapper id={`${id}`} to={`/details/${id}`} {...props}>
        <ResponsiveTileCard>
          <AppIcon src={img} />
          <InfoWrapper>
            <Title>{name}</Title>
            <SubTitle>{author}</SubTitle>
          </InfoWrapper>
        </ResponsiveTileCard>
      </LinkWrapper>
    );
  }
}

export default AppTileCard;
