import React from 'react';
import { LoadingPage } from './LoadingPage';

import {
  CalloutSection,
  InfoText,
  ButtonLink
} from '../components/basic-components';
import { useI18n } from '../hooks';
import {
  GetEEAUserVariables,
  GetEEAUserResponse,
  getEEAUser
} from '../graphql/getPageLayout';
import { ChildDataProps, graphql } from 'react-apollo';

export interface Props {
  isApp: boolean;
  clientIpAddress?: string;
}

type ChildProps = ChildDataProps<
  Props,
  GetEEAUserVariables,
  GetEEAUserResponse
>;

const AllCallout: React.FC<ChildProps> = (props) => {
  const { t } = useI18n();
  const { isApp, data } = props;
  const { loading } = data;

  if (loading) {
    return <LoadingPage />;
  }

  const calloutText = isApp ? t`All Apps` : t`All Clocks`;

  let content;
  if (!data.isEEAUser) {
    content = (
      <CalloutSection>
        <h2>{calloutText}</h2>
        <InfoText>{t`Get more out of your smartwatch`}</InfoText>
        <ButtonLink
          to={`/${isApp ? 'apps' : 'clocks'}/all`}
          data-allappstype={isApp ? 'apps' : 'clocks'}
          data-allappslink
        >
          {t`See More`}
        </ButtonLink>
      </CalloutSection>
    );
  } else {
    content = null;
  }

  return <p>{content}</p>;
};

export default graphql<
  Props,
  GetEEAUserResponse,
  GetEEAUserVariables,
  ChildProps
>(getEEAUser, {
  options: (props) => ({
    variables: {
      clientIpAddress: props.clientIpAddress
    }
  })
})(AllCallout);
