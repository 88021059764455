import styled from 'styled-components';

import { below } from '../media-query-utils';

const CardList = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin: -12px;

  justify-content: flex-start;

  ${below('mobile')} {
    margin: -6px 0;

    justify-content: space-between;
  }

  & > * {
    padding: 12px;

    ${below('mobile')} {
      padding: 6px 0;
    }
  }
`;

export default CardList;
