import React from 'react';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';

import { NoStyleLink, baseFitLinkStyle } from '../basic-components';
import { DesktopList, SeeAllCard, Wrapper } from './common';
import { above } from '../../media-query-utils';
import { useI18n } from '../../hooks';

interface Props {
  children: React.ReactNode;
  outlink: string;
  type: string;
  seeAllProps?: any;
}

const MobileList = styled(SwipeableViews).attrs({
  style: {
    overflowX: 'visible',
    marginRight: '-10px'
  },
  slideStyle: {
    overflow: 'visible',
    paddingRight: '10px'
  },
  resistance: true,
  disableLazyLoading: true
})`
  ${above('mobile')} {
    display: none;
  }
`;

const MobileAppGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  & > * {
    margin: 5px 0;
  }
`;

const SeeAllLinkText = styled.div`
  ${baseFitLinkStyle}
`;

const groupChildren = (children: React.ReactNode) => {
  let group = 1;
  let subList: React.ReactNode[] = [];
  const result: React.ReactNode[][] = [];
  React.Children.forEach(children, (child, index) => {
    if (index / 3 < group) {
      subList.push(child);
    } else {
      result.push(subList);
      subList = [child];
      group++;
    }
  });
  result.push(subList);
  return result;
};

const AppRow: React.FC<Props> = ({ children, outlink, seeAllProps }) => {
  const { t } = useI18n();

  return (
    <Wrapper>
      <MobileList resistance>
        {groupChildren(children).map((set, index) => (
          <MobileAppGroup key={index}>{set}</MobileAppGroup>
        ))}
      </MobileList>
      <DesktopList>
        {children}
        <NoStyleLink to={outlink} {...seeAllProps}>
          <SeeAllCard>
            <SeeAllLinkText
              id={`${outlink}`}
              showArrow
            >{t`See All`}</SeeAllLinkText>
          </SeeAllCard>
        </NoStyleLink>
      </DesktopList>
    </Wrapper>
  );
};

export default AppRow;
