import styled from 'styled-components';

import TileCard from '../TileCard';
import { below } from '../../media-query-utils';

export const DesktopList = styled.div`
  display: flex;

  & > * {
    margin-right: 24px;

    flex-shrink: 0;
  }

  // We hide the cards in the discovery list based on screen size. Due to the
  // card size, there's a little offset from our normal breakpoints for desktop

  ${below('largeDesktop', -40)} {
    & > :nth-child(5):not(:last-child) {
      display: none;
    }
  }

  ${below('mediumDesktop')} {
    & > :nth-child(4):not(:last-child) {
      display: none;
    }
  }

  ${below('largeTablet')} {
    & > :nth-child(3):not(:last-child) {
      display: none;
    }
  }

  ${below('smallTablet')} {
    & > :nth-child(2):not(:last-child) {
      display: none;
    }
  }

  ${below('mobile')} {
    display: none;
  }
`;

export const SeeAllCard = styled(TileCard)`
  display: flex;
  justify-content: center;
  align-items: center;

  &&& {
    margin: 0;
  }
`;

export const Wrapper = styled.div``;
