import React from 'react';

import AppRow from './AppRow';
import ClockRow from './ClockRow';

interface Props {
  children: React.ReactNode;
  outlink: string;
  type: string;
  seeAllProps?: any;
}

export default class DiscoveryCardRow extends React.Component<Props> {
  public render() {
    const { type } = this.props;

    return (
      <div>
        {type === 'APP' ? (
          <AppRow {...this.props} />
        ) : (
          <ClockRow {...this.props} />
        )}
      </div>
    );
  }
}
