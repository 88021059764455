import * as React from 'react';
import styled, { css } from 'styled-components';
import TileCard, { Title, SubTitle } from './TileCard';
import { NoStyleLink } from './basic-components';
import { below } from '../media-query-utils';

interface Props {
  img: string;
  name: string;
  author: string;
  id: string;
  isPremium?: boolean;
}

const LinkWrapper = styled(NoStyleLink)`
  ${below('mobile')} {
    width: 43vw;
  }
`;

const InfoWrapper = styled.div`
  padding: 10px;
`;

const Image = styled.div`
  width: 100%;
  padding-top: 100%;

  ${({ src, isPremium }: { src: string; isPremium?: boolean }) => css`
    background-image: url(${src});
    background-size: cover;
    background-position: center;

    border-radius: ${isPremium ? '10px 10px 0px 0px' : '0px'};
  `}
`;

const ClockCard = styled(TileCard)`
  ${below('mobile')} {
    width: auto;
    height: auto;
  }

  ${({ isPremium }: { isPremium?: boolean }) =>
    isPremium
      ? css`
          position: relative;
          overflow: visible;
          border: solid 5px transparent;
          background-clip: padding-box;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -5px;
            border-radius: inherit;
            background: linear-gradient(
              110deg,
              #bb8b3c 0%,
              #ffffff 50%,
              #ffd693 100%
            );
          }
        `
      : ''}
`;

class ClockTileCard extends React.Component<Props> {
  public render() {
    const { img, name, author, id, isPremium, ...props } = this.props;

    return (
      <LinkWrapper id={`${id}`} to={`/details/${id}`} {...props}>
        <ClockCard isPremium={isPremium}>
          <Image src={img} isPremium={isPremium} />
          <InfoWrapper>
            <Title>{name}</Title>
            <SubTitle>{author}</SubTitle>
          </InfoWrapper>
        </ClockCard>
      </LinkWrapper>
    );
  }
}

export default ClockTileCard;
