import React from 'react';
import DiscoveryBanner, {
  Props as DiscoveryBannerProps
} from './DiscoveryBanner';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { colors } from '../style-constants';
import { below, above } from '../media-query-utils';
import { Banner } from '../types';
import { times } from '../utils';
import { graphql, ChildDataProps } from 'react-apollo';
import { getApp, GetAppResponse, Screenshot } from '../graphql/getApp';
import { Section } from './basic-components';

interface Props {
  banners: Banner[];
}

interface State {
  currentBanner: number;
}

interface DotsProps {
  num: number;
  active: number;
  mobile?: boolean;
}

interface Variables {
  id: string;
}

type AppChildProps = ChildDataProps<
  DiscoveryBannerProps,
  GetAppResponse,
  Variables
>;

const SLIDE_CLASS = 'discovery-carousel-slide';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = styled(AutoPlaySwipeableViews).attrs({
  style: {
    overflowX: 'inherit'
  },
  slideStyle: {
    overflow: 'visible'
  },
  slideClassName: SLIDE_CLASS,
  interval: 4000,
  resistance: true,
  disableLazyLoading: true
})`
  width: 100%;

  ${above('mobile')} {
    background-color: white;

    & .react-swipeable-view-container {
      transition: transform 0s ease 0.25s !important;
    }
  }

  ${below('mobile')} {
    & .${SLIDE_CLASS} {
      padding-right: 10px;
    }
  }
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  border-bottom: 2px solid ${colors.silver};
  border-right: 2px solid ${colors.silver};
  box-shadow: 1px 0 0 0 rgba(196, 196, 196, 0.5),
    0 1px 0 0 rgba(196, 196, 196, 0.5);

  ${below('mobile')} {
    display: none;
  }
`;

const NextBannerArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  left: 95%;

  transform: rotate(-45deg);
`;

const PrevBannerArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  left: 5%;

  transform: rotate(135deg);
`;

const Dot = styled.div`
  border-radius: 50%;
  background: ${colors.gainsboro};
  width: 9px;
  height: 9px;

  margin: 0px 5px;
`;

const DiscoveryBannerCarouselSection = styled(Section)`
  position: relative;
  width: auto;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  ${above('mobile')} {
    padding: 0;
    margin-bottom: 50px;
    overflow-x: hidden;
  }

  ${below('mobile')} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const ActiveDot = styled(Dot)`
  background: ${colors.irisBlue};
`;

interface DotBoxProps {
  isMobile?: boolean;
}

const DotBox = styled.div<DotBoxProps>`
  display: ${(props) => (props.isMobile ? 'none' : 'flex')};

  position: absolute;
  bottom: 10%;

  ${below('mobile')} {
    position: static;
    display: ${(props) => (props.isMobile ? 'flex' : 'none')};
    margin-left: 10px;
    margin-top: 20px;
  }
`;

const Dots: React.FC<DotsProps> = ({ active, mobile: isMobile, num }) => (
  <DotBox isMobile={isMobile}>
    {times(num, (index) => {
      {
        return active === index ? (
          <ActiveDot key={index} />
        ) : (
          <Dot key={index} />
        );
      }
    })}
  </DotBox>
);

const withApp = graphql<
  DiscoveryBannerProps,
  GetAppResponse,
  Variables,
  AppChildProps
>(getApp, {
  options: (props) => ({
    variables: {
      id: props.link
    }
  })
});

const BannerWithApp = withApp(({ data: { loading, error, app }, ...props }) => {
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  let screenshot: Screenshot | undefined;
  if (!(props.bannerOptions && props.bannerOptions.hideScreenshot)) {
    screenshot = app && app.screenshots && app.screenshots[0];
  }

  return (
    <DiscoveryBanner
      active={props.active}
      bannerImage={props.bannerImage}
      title={props.title}
      subtitle={app && app.developer.name}
      description={props.description}
      linkText={props.linkText}
      linkType={props.linkType}
      link={props.link}
      screenshot={screenshot}
      dots={props.dots}
    />
  );
});

export default class DiscoveryBannerCarousel extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
  }

  public state = {
    currentBanner: 0
  };

  private nextBanner = () => {
    this.setState((state, props) => ({
      currentBanner: (state.currentBanner + 1) % props.banners.length
    }));
  };

  private prevBanner = () => {
    this.setState((state, props) => ({
      currentBanner:
        (state.currentBanner + props.banners.length - 1) % props.banners.length
    }));
  };

  private handleChangeIndex = (index: number) => {
    this.setState({
      currentBanner: index
    });
  };

  private renderBanner = (banner: Banner, index: number) => {
    let linkType = '';
    switch (banner.button.link.type) {
      case 'URL':
        linkType = 'url';
        break;
      case 'CATEGORY':
        linkType = 'category';
        break;
      case 'COLLECTION':
        linkType = 'collection';
        break;
      case 'APP':
      case 'CLOCK':
      default:
        linkType = 'details';
    }

    return (
      <BannerWithApp
        key={index}
        bannerImage={banner.background.uri}
        bannerOptions={banner.options}
        title={banner.title}
        description={banner.text}
        linkText={banner.button.text}
        link={banner.button.link.resourceId}
        linkType={linkType}
        active={index === this.state.currentBanner}
        dots={
          this.props.banners.length > 1 && (
            <Dots
              num={this.props.banners.length}
              active={this.state.currentBanner}
            />
          )
        }
      />
    );
  };

  public render() {
    const { banners } = this.props;

    return (
      <DiscoveryBannerCarouselSection>
        <Carousel
          index={this.state.currentBanner}
          onChangeIndex={this.handleChangeIndex}
        >
          {banners.map(this.renderBanner)}
        </Carousel>
        {banners.length > 1 && (
          <React.Fragment>
            <NextBannerArrow onClick={this.nextBanner} />
            <PrevBannerArrow onClick={this.prevBanner} />
            <Dots
              num={banners.length}
              active={this.state.currentBanner}
              mobile
            />
          </React.Fragment>
        )}
      </DiscoveryBannerCarouselSection>
    );
  }
}
